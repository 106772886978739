import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  useDisclosure,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Image,
  InputGroup,
  InputLeftAddon,
  Center,
} from "@chakra-ui/react";
import { usePaystackPayment } from "react-paystack";
import useUser from "hooks/useUser";
import toast from "react-hot-toast";
import { useSettings } from "hooks/actions";
import { useCryptomusWalletQR } from "hooks/actions";
import { useUserDetails } from "hooks/actions";
import { MdCopyAll, MdOpenInNew } from "react-icons/md";

const FundAccount = () => {
  // const [reference, setReference] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [rate, setRate] = useState(null);
  const { refreshUser, userDetails: user } = useUser();
  const { userDetails } = useUserDetails();
  const [loading, setLoading] = useState(false);

  const [usdAmount, setUsdAmount] = useState(0);
  const [nairaAmount, setNairaAmount] = useState(0);

  const publicKey = "pk_live_775d51fafb960978cb66177e3f6c3fec350c1487";

  const { settings: settingsRes } = useSettings();
  useEffect(() => {
    if (settingsRes?.data) {
      setRate(settingsRes?.data?.rate);
    }
  }, [settingsRes]);

  const handleUsdChange = (e) => {
    const value = parseFloat(e.target.value);
    setUsdAmount(value);
    if (rate) {
      setNairaAmount(Math.round(value * rate));
    }
  };

  const handleNairaChange = (e) => {
    const value = parseFloat(e.target.value);
    setNairaAmount(value);
    if (rate) {
      setUsdAmount(Math.round((value / rate) * 100) / 100);
    }
  };

  const config = {
    reference: new Date().getTime(),
    email: user?.email,
    amount: nairaAmount * 100,
    publicKey: publicKey,
    // metadata: {
    //   tag: "naira",
    // },
  };

  const onSuccess = (response) => {
    console.log(response);
    // setReference(response);
    refreshUser();
    toast.success(
      "Payment successful, please wait for a few seconds for your wallet to be credited ",
      {
        duration: 15000,
      }
    );
    setTimeout(() => {
      window.location.reload();
    }, 5000);
    onClose();
  };

  const { walletQR } = useCryptomusWalletQR();
  useEffect(() => {
    if (walletQR?.data) {
      console.log(walletQR?.data, "WALLET QR");
    }
  }, [walletQR]);

  const initializePayment = usePaystackPayment(config);

  const initCryptomusPay = async () => {
    setLoading(true);
    try {
      if (userDetails?.data?.wallet_payment_url) {
        window.open(userDetails?.data?.wallet_payment_url);
      } else {
        toast.error("An error occured");
      }

      // console.log(res, "RES");
      // console.log(cryptomusAmount, "CRYPTOMUS ACCOUNT");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        disabled={!user}
        w="fit-content"
        h="50"
        mb={3}
        onClick={onOpen}
      >
        Fund Account
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Fund Your Account</ModalHeader>
          <Text ml={2}>
            Choose desired your payment gateway to fund your account.
          </Text>
          <ModalCloseButton />
          <Tabs isFitted>
            <TabList>
              <Tab _focus={{ outline: "none" }}>
                <Text mr={2}>Paystack</Text>
                <Image
                  src="https://ps.w.org/ultimate-membership-pro-paystack/assets/icon-256x256.png?rev=2805526"
                  width={30}
                  height={30}
                />
              </Tab>

              <Tab _focus={{ outline: "none" }}>
                <Image
                  src="https://www.saashub.com/images/app/service_logos/221/faybyph64mwc/large.png?1663652462"
                  width={30}
                  height={30}
                />{" "}
                <Text ml={2}>Cryptomus</Text>
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <ModalBody>
                  <Box>
                    <Text fontSize="sm" mb={4}>
                      Rate: {`$1 = ${rate} NGN`}
                    </Text>
                  </Box>
                  <Box>
                    <InputGroup size="md" mt={1.5}>
                      <InputLeftAddon
                        pointerEvents="none"
                        color="gray.300"
                        children="$"
                      />
                      <Input
                        type="number"
                        placeholder="Enter amount in USD"
                        value={usdAmount}
                        min={0}
                        onChange={handleUsdChange}
                        variant="auth"
                        roundedBottomLeft={"none"}
                        roundedTopLeft={"none"}
                        roundedBottomRight={"md"}
                        roundedTopRight={"md"}
                      />
                    </InputGroup>

                    <InputGroup my={3}>
                      <InputLeftAddon
                        pointerEvents="none"
                        color="gray.300"
                        children="₦"
                      />
                      <Input
                        type="number"
                        placeholder={"Enter amount in NGN"}
                        value={nairaAmount}
                        min={0}
                        onChange={handleNairaChange}
                        variant="auth"
                        roundedBottomLeft={"none"}
                        roundedTopLeft={"none"}
                        roundedBottomRight={"md"}
                        roundedTopRight={"md"}
                      />
                    </InputGroup>
                  </Box>
                  {nairaAmount ? (
                    <Button
                      fontSize="sm"
                      variant="brand"
                      fontWeight="500"
                      width="100%"
                      mt="6"
                      h="50"
                      mb={3}
                      onClick={() => {
                        initializePayment(onSuccess);
                      }}
                    >
                      {`Pay ₦${parseInt(
                        nairaAmount ?? 0
                      ).toLocaleString()} with Paystack`}
                    </Button>
                  ) : (
                    <Button
                      disabled
                      fontSize="sm"
                      variant="brand"
                      fontWeight="500"
                      width="100%"
                      mt="6"
                      h="50"
                      mb={3}
                    >
                      Pay with Paystack
                    </Button>
                  )}
                </ModalBody>
              </TabPanel>

              <TabPanel>
                <ModalBody>
                  {/* {cryptomusAmount < 5 && (
                    <Text color="red.300" fontSize={12}>
                      Minimum amount: $5
                    </Text>
                  )} */}
                  {/* <InputGroup size="md" mt={4}>
                    <InputLeftAddon
                      pointerEvents="none"
                      color="gray.300"
                      children="$"
                    />
                    <Input
                      type="number"
                      placeholder="Enter amount in USD"
                      value={cryptomusAmount}
                      onChange={handleUSDAmountChange}
                      variant="auth"
                    />
                  </InputGroup> */}
                  <Text>
                    We have succesfully generated a{" "}
                    <span
                      style={{
                        fontSize: 18,
                        color: "green",
                        fontWeight: "bold",
                      }}
                    >
                      {userDetails?.data?.wallet_currency}
                    </span>{" "}
                    address for you, please ONLY send on the{" "}
                    <span
                      style={{
                        fontSize: 18,
                        color: "orangered",
                        fontWeight: "bold",
                      }}
                    >
                      {userDetails?.data?.wallet_network?.toUpperCase()} (TRC20)
                    </span>{" "}
                    network to this address{" "}
                    <Text
                      as="span"
                      // style="color:red"
                      _hover={{
                        cursor: "pointer",
                        color: "orange",
                        opacity: 0.5,
                      }}
                      style={{
                        color: "orange",
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          userDetails?.data?.wallet_address
                        );
                        toast.success("Wallet address copied");
                      }}
                    >
                      <i>{userDetails?.data?.wallet_address}</i>
                    </Text>{" "}
                    or your funds will be permanently lost, do not send any
                    other token to this address. <br /> ShawnSMS will not be
                    liable for failed transactions.
                  </Text>
                  <Center>
                    <Box borderColor={"orange"} borderWidth={2} my={5}>
                      <Image
                        width={300}
                        height={300}
                        src={walletQR?.data?.image}
                      />
                      <Text textAlign={"center"} fontSize={"small"}>
                        {userDetails?.data?.wallet_address}
                      </Text>
                      {/* <Text>{userDetails?.data?.wallet_network}</Text>
                      <Text>{userDetails?.data?.wallet_currency}</Text> */}
                    </Box>
                  </Center>
                  <Button
                    fontSize="sm"
                    // isDisabled={cryptomusAmount < 5}
                    variant="outline"
                    fontWeight="500"
                    width="100%"
                    mt="6"
                    h="50"
                    rightIcon={<MdCopyAll />}
                    disabled={!userDetails?.data?.wallet_address}
                    isLoading={loading}
                    loadingText="Initializing payment..."
                    mb={3}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        userDetails?.data?.wallet_address
                      );
                      toast.success("Wallet address copied");
                    }}
                    // onClick={() => {
                    //   initCryptomusPay();
                    // }}
                  >
                    Copy Wallet Address
                  </Button>
                  <Center>Or</Center>
                  <Button
                    fontSize="sm"
                    // isDisabled={cryptomusAmount < 5}
                    variant="brand"
                    fontWeight="500"
                    width="100%"
                    mt="6"
                    h="50"
                    rightIcon={<MdOpenInNew />}
                    isLoading={loading}
                    loadingText="Initializing payment..."
                    mb={3}
                    onClick={() => {
                      initCryptomusPay();
                    }}
                  >
                    Pay Manually
                  </Button>
                </ModalBody>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default FundAccount;
